﻿#dialog-notify {

	input.ok { float: right; }
	input.cancel { float: right; }
	.submit-line{ text-align: right;}

	input[type="button"] {
		background: url("/App_Themes/Default/Images/Downloads-Activities/button-bg.gif") repeat-x scroll 0 0 transparent;
		border: 1px solid #828488;
		border-radius: 4px 4px 4px 4px;
		clear: none;
		color: #6E6E6E;
		cursor: pointer;
		float: none;
		font-weight: bold;
		height: 22px;
		margin: 9px 5px 0 0;
		padding: 0 18px;
		text-align: center;
	}

	input[type="submit"] { height: 22px; text-align: center; background: transparent url(/App_Themes/Default/Images/Downloads-Activities/button-bg.gif) 0 0 scroll repeat-x; border: solid 1px #828488; padding: 0 18px; margin: 9px 5px 0 0; color: #6e6e6e; cursor: pointer; clear: none; @include border-radius( 4px ); font-weight: bold; ;  float: none; }

}


#dialog-report-issue{
    textarea{ width: 300px; }
    input.text, input[type="text"]{ width: 150px; }

	
	.form-line{
		overflow: hidden;
		margin-bottom: 10px;
		vertical-align: middle;

		label{
			text-align: right;
			display: block;
			float: left;
			overflow: hidden;
			margin: 0 10px;
			width: 75px;
		}
	}

	input[type="submit"] { height: 22px; text-align: center; background: transparent url(/App_Themes/Default/Images/Downloads-Activities/button-bg.gif) 0 0 scroll repeat-x; border: solid 1px #828488; padding: 0 18px; margin: 9px 5px 0 0; color: #6e6e6e; cursor: pointer; clear: none; @include border-radius( 4px ); font-weight: bold; ;  float: none; }
	.submit-line{
		text-align: center;
		.submit:first-child, .submit.first-child { margin-left: 0px; }
	}

}


	

#dialog-email-a-friend{
		
	fieldset{ float: left; display: block; overflow: hidden; padding-right: 10px;
		legend{ font-weight: bold; }
	}
	#emailafriend-subject{
		width: 315px;
	}
	textarea{ width: 315px; }
	#recaptcha_area{
		margin: 0 0 0 90px;
	}

	.form-line{
		overflow: hidden;
		margin-bottom: 10px;
		vertical-align: middle;
	
		label{
			text-align: right;
			display: block;
			float: left;
			overflow: hidden;
			margin: 0 10px;
			width: 75px;
		}

		input[type="submit"] { height: 22px; text-align: center; background: transparent url(/App_Themes/Default/Images/Downloads-Activities/button-bg.gif) 0 0 scroll repeat-x; border: solid 1px #828488; padding: 0 18px; margin: 9px 5px 0 0; color: #6e6e6e; cursor: pointer; clear: none; @include border-radius( 4px ); font-weight: bold; ;  float: none; }

	}
	.clear{ clear: both; }
	.loading { background: transparent url(/App_Themes/Default/Images/ajax-loader.gif) center center scroll no-repeat; height: 70px!important; overflow: hidden; }

	.submit-line{
		text-align: center;
		.submit:first-child, .submit.first-child { margin-left: 0px; }
	}

}

#detail-ax{
	font-family: Arial,Helvetica,sans-serif;
	padding: 0 0 0 15px;


	.document-player {padding-left: 18px; background: url("/App_Themes/Default/Images/Downloads-Activities/dp-small.png") left center no-repeat; font-size: 14px; font-weight: bold; text-decoration: none; 
		&:hover{text-decoration: underline;}
	}

	input[type="submit"] { height: 22px; text-align: center; background: transparent url(/App_Themes/Default/Images/Downloads-Activities/button-bg.gif) 0 0 scroll repeat-x; border: solid 1px #828488; padding: 0 18px; margin: 9px 5px 0 0; color: #6e6e6e; cursor: pointer; clear: none; @include border-radius( 4px ); font-weight: bold; ;  float: none; }
	.submit-line{
		text-align: center;
		.submit:first-child, .submit.first-child { margin-left: 0px; }
	}


	.activity-video-download{ 
		padding: 5px 0 0 3px; 
		background: url("/App_Themes/Default/Images/Downloads-Activities/line-above-download.jpg") no-repeat;		
		a:link, a:visited, a:focus, a:active, a:link:hover { color: 1973b4; }
		a:focus, a:active, a:link:hover, a:hover { text-decoration: underline; }
	}

	#video-links li {
		list-style:none !important;
		img { vertical-align:middle; }
		a{ font-weight:bold; color:#CB0C0F; }
	}
		

	.subheader{ padding: 0 0 20px 0;
		ul{ margin: 0; list-style: none; clear: left; overflow: hidden;
			li{ float: left; margin-left: 10px}
			li:first-child, li.first-child { margin-left: 0px; }
		}

		h4, h3{
			clear: none;
			display: inline;
			a { clear: none; }
		}

		h3,h3 a{
			background-image: none;
			color: $fg-red-header;
			font-size: 14px;
			font-weight: bold;
		}

		h4,h4 a{
			font-weight: normal;
			font-size: 12px;
			color: #606060;
		}

		a{
			color: #1973B4;
			font-size: 12px;
		}

		h4 a, a{
			color: #606060;
			background: url(/App_Themes/Default/Images/Downloads-Activities/tbody-expand.gif) no-repeat top left;
			vertical-align: center;
			padding: 5px 0 5px 10px;
			line-height: 16px;

			&.return{ background: url(/App_Themes/Default/Images/Downloads-Activities/back-red-arrow.png) no-repeat center left; color: #1973B4; }
		}

	}
	#activity-hero-image-container.fltrt{padding: 0 0 30px 30px; }
	#activity-hero-image-container.fltlft{padding: 0 30px 30px 0; }


	#activity-header{
		
		overflow: hidden;
	}
	#activity-header + #activity-hero-image-container.fltrt{position: relative; top: -48px; }


	#activity-navigation{
		float: right;
		overflow: hidden;
		ul{ list-style: none; }

		#activity-nav-head{
			width: 245px;
			height: 31px;
			background: url(/App_Themes/Default/Images/Downloads-Activities/sidebar-header-bg.gif) no-repeat;
			li {
				a{
					overflow: hidden;
					text-indent: -9999px;
					display: block;
					width: 19px;
					height: 19px;
				}
				width: 19px;
				height: 19px;
				padding: 6px 0 0 18px;
				float: left;
			}
			.likes-icon a{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat 0px 0px; }
			.collection-icon a{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat -19px 0px; }
			.email-icon a{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat -38px 0px; }
			.facebook-icon a{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat -57px 0px; }
			.twitter-icon a{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat -76px 0px; }
			.print-icon a{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat -95px 0px; }

			.likes-icon a:hover{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat 0px -19px; }
			.collection-icon a:hover{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat -19px -19px; }
			.email-icon a:hover{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat -38px -19px; }
			.facebook-icon a:hover{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat -57px -19px; }
			.twitter-icon a:hover{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat -76px -19px; }
			.print-icon a:hover{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat -95px -19px; }

			.collection-icon.saved a{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat -19px -38px; }
			.likes-icon a.recommended{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat 0px -38px; }
		}
		#activity-nav-actions{
			.icon{float: left; }
			.report-issue{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-report-issue.gif) no-repeat center left;}
			h5{padding: 8px 0 4px 25px; border-bottom: none; margin: 0; }
			background: url(/App_Themes/Default/Images/Downloads-Activities/sidebar-content-bg.gif) no-repeat;
			padding: 10px 0 0 10px;
			overflow: visible;
			min-height: 301px;
			width: 245px;
			ul{ padding: 0 0 0 25px;
				li { 
					a { display: block; line-height: 14px; margin-bottom: 4px; }
				}
				h5 {padding: 0; }
				li { padding: 0; }
			}
			p {	margin: 0px; line-height: 1.4em; padding:0 1em 0 25px; }

			div { margin: 0px; line-height: 1.4em; }

			div.axOtherMaterials {
				margin: 0px; line-height: 1.4em;  padding:0 1em 0 25px; 
				ul { padding: 0px !important; }
			}

			.tech-tip-icon{ background: url(/App_Themes/Default/Images/Downloads-Activities/ax-icon-preview-video-18x18.png) no-repeat 0px bottom;}

			.etcategorylist { margin: 0 0 0 -5px; line-height: 14px;
				.etcategory { padding: 0 0 2px; position: relative;
					.etcategoryicon { position: absolute; left: -10px; }

					.etcategoryname { padding-left: 5px; }

				}

			}
		}
	}
	#activity-content{
		width: 645px;
		overflow: hidden;
		#divhero{float:left; width:auto}
		#divflash{float:left; width:auto; padding:0 10px;}
		h3{ color: #CB0C0F; font-size: 14px; font-weight: bold; padding: 0; margin: 0; }
		h2.page_title_red, span.page_title_red { color: #CB0C0F; font-size: 14px; font-weight: bold; padding: 0; margin: 0; }
		h4{ color: #1973b4; font-size: 14px; font-weight: bold; padding: 0 0 5px; margin: 0; background: #FFF; }
		p{ font-size: 12px;	line-height: 1.4em; padding: 0 0 0.5em; }

		div { font-size: 12px; line-height: 1.4em; }

		ul{ margin: 0 0 15px 0; padding-left: 18px;
			li { 
				list-style-image: url(/App_Themes/Default/Images/Downloads-Activities/bullet-red-5x5.gif);
				font-size: 12px;
				line-height: 1.4em;
				padding: 3px 0px;
			}
		}
		.image-container img, .image-container object, .image-container .image-placeholder {
			padding: 5px;
			background: $border;
			@include border-radius( 5px 5px 5px 5px );
		}

		.image-placeholder { overflow: hidden; width: 240px; height: 240px;
			span { display: block; width: 240px; height: 240px; background: #fff; text-align: center; }
		}

		.underline{
			padding-bottom: 5px;
			margin-bottom: 5px;
			border-bottom: #737373 1px solid;
			display:block;
		}

		.ad-overline{
			padding: 10px 0 0 0;
			margin: 12px 0 10px 0;
			border-top: #737373 1px solid;
		}

		.fltrt img{ margin-left: 15px; }
		.fltlft img{ margin-right: 15px; }

		&.OVA{
			#divhero{float:right; width:auto; text-align:right;}
			#divflash{float:right; width:auto; text-align:right;}
			#activity-header{ float: left; }
			.activity-overview-container{clear: left; }
			.image-container{
				float: right;
				&.fltlft img{ margin: 0 0 0 15px }
			}
		}
		.bc-standards { display:none}

	}

	.activity-overview-container,
	#activity-info-before,
	#activity-info-during,
	#activity-info-after{margin-bottom: 15px; }
	h3{ font-size: 18px; color: #606060; display: inline-block; }
	h5, h4{ clear: none; font-size: 14px; font-weight: bold; color: #5f5f5f; padding-top: 20px; }
	h5{ font-size: 12px; padding: 10px 0 0 0;  a{ font-weight: bold; } }
	#activity-key-steps-container{
		clear: both;
		padding-top: 10px;
		#slideshow{
			position: relative;
			* html & { height: 205px; }

			ul{ min-height: 205px;
				overflow: visible;
				padding: 0;
				margin: 0;
			}
			li.slide {
				width: 645px;
				position: absolute;
				top: 10px;
				list-style-type: none;
				list-style-image: none;

				img{
					float: right;
					margin: 0 0 0 20px;
				}

				p{
					margin-top: 0px;
				}

			}
		}
		#pagination-list{
			float: right;
			li{
				float: left;
				display: block;
				text-align: center;
				line-height: 16px;
				font-size: 11px;
				width: 16px;
				height: 16px;
				margin: 2px;
				background: #b3b3b3;
				color: #fff;
				font-weight: bold;
			}
			li.selected{
				background: #c21600;
			}
		}
	}

}
