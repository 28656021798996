﻿.loading { background: transparent url(/App_Themes/Default/Images/ajax-loader.gif) center center scroll no-repeat; height: 70px!important; overflow: hidden; }
.error{
	background: $bg-error;
	color: $fg-error;
	overflow: hidden;
	border: solid 1px $fg-error; padding: 10px;
	display: block;
	ul{
		padding-left: 20px;
		li { list-style-type: disc!important; }
	}
}
$fg-error: #DD0000;
$bg-error: #FFDDCC;

#dialog-submit-textbook {
		
		 input.text, input[type="text"]{ width: 105px; background: none repeat scroll 0 0 #FFFFFF;color: #525252; }

		 input[type="button"] {
			background: url("/App_Themes/Default/Images/Downloads-Activities/button-bg.gif") repeat-x scroll 0 0 transparent;
			border: 1px solid #828488;
			border-radius: 4px 4px 4px 4px;
			clear: none;
			color: #6E6E6E;
			cursor: pointer;
			float: none;
			font-weight: bold;
			height: 22px;
			margin: 9px 5px 0 0;
			padding: 0 18px;
			text-align: center;
			}

		 textarea{ width: 150px; }

		p {
			line-height: 1.4em;
			margin: 0;
			padding: 0 0 0.5em;
		}
		.dlglabel{ float: left; margin: 0 10px 0 0; width: 65px; display: block; text-align: right;}
		.form-line{
			overflow: hidden;
			margin-bottom: 10px;
			vertical-align: middle;
	
			label{
				text-align: right;
				display: block;
				float: left;
				overflow: hidden;
				margin: 0 10px;
				width: 65px;
			}
		}
		.submit-line{
			text-align: center;}
	}


#left-container{
			float: left;
			width: 680px;	
			font-family: Arial,Helvetica,sans-serif;
			

				#activities-right-nav, #activities-left-nav{
					width: 680px;
					height: 26px;
					overflow: hidden;
					padding: 0px;
					margin: 0px;
					font-size: 1em;
					z-index: 1; 
					position: absolute; 
					margin-top: -22px;
					li{
						float: left;
						list-style: none;
						height: 26px;
						line-height: 26px;
						background: url(/App_Themes/Default/Images/Downloads-Activities/ax-landing-nav-tab-bg.png) repeat-x;
						padding: 0  25px 0 0;

						a{
							text-transform: uppercase;
							font-size: 14px;
							font-weight: bold;
							padding: 0 5px 0 26px;
							display: block;
							line-height: 26px;
							background: url(/App_Themes/Default/Images/Downloads-Activities/ax-landing-nav-tab-bg.png) repeat-x;
							color: #525252;
						}


					}

					li.selected a{
						color: $fg-red-header;
					}

					li:last-child,
					li.last-child{
						padding: 0 26px 0 0;
						background: url(/App_Themes/Default/Images/Downloads-Activities/ax-landing-nav-tab-end-right.png) top right no-repeat;
					}
				}

				


				#activities-right-nav{
					float: right;
					li a{
						padding:  0 26px 0 5px ;
						text-transform: none;
					}

					li.first{
						padding: 0 0 0 26px;
						background: url(/App_Themes/Default/Images/Downloads-Activities/ax-landing-nav-tab-end-left.png) top left no-repeat;

					}

				}
				
			
			#activities-nav-display{
				height: 195px;
				width: 640px;
				padding: 16px 0 0 40px;
				background: url(/App_Themes/Default/Images/Downloads-Activities/ax-landing-search-field-bg.gif) no-repeat;
				overflow: hidden;



				#activities-find-by{
					float: left;
					width: 210px;

					li{
						padding: 0 0 0 25px;
					}
					li, li a{
						line-height: 26px;
						color: #1F6DCD;
						font-weight: bold;

						cursor: pointer;

					}
					li.selected{
						background: url(/App_Themes/Default/Images/Downloads-Activities/ax-landing-search-active-bg.png) no-repeat;
					}
					li a:hover, li.hover, li:hover{
						text-decoration: underline;
					}
					li a.aspNetDisabled:hover { text-decoration: none; }
				}
				#activities-find-by-display{
					float: right;
					width:400px;
					li{
						font-size:14px;
						font-weight: bold;
						display: none;
						height: 195px;
						overflow: hidden;
						overflow-y: auto;
						width: 400px;
						padding-right: 25px;
						text-align:right;
						div{
							width: 310px;
						}
					}
					li.selected{
						display: inline-block;

					}
					li.redirect{
						background: transparent url(/App_Themes/Default/Images/Downloads-Activities/redirect-loader.gif) no-repeat center 75px ;
					}

					.activities-search-options{
						margin: 0;
						label{
							float: left;
							width: 100px;
						}
						select{
							float: right;
							width: 200px;
						}

						input.submit{
							margin-left: 140px;

						}
						input.text{
							float: right;
							width: 200px;
						}

					}
				}
			}
		}