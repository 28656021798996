﻿.sublayout-activity-microsite-listing { position: relative; width: 960px; overflow: hidden;
	&:before { content: ""; display: block; position: absolute; left: 0; right: 0; top: 0; height: 7px; @include gradient-top-bottom( rgba(172, 172, 172, 1), rgba(172, 172, 172, 0), #fff ); z-index: 10; }

	> .column-1 { float: left; width: 185px; }

	> .column-2 { float: left; width: 731px; padding: 19px 22px 0; }
	
	.sublayout-activity-microsite-breadcrumb { width: 100%; overflow: hidden; padding: 0 0 12px;
		.etbreadcrumb { display: block; float: left; padding: 0 13px 0 15px; font: bold 20px/20px Arial,Helvetica,sans-serif; position: relative;
			&:after { content:""; display: block; width: 0; height: 19px; border-left: solid 1px #999; border-right: solid 1px #707070; position: absolute; left: 0; top: 2px; }

			&:link,
			&:visited { color: #cb0c0f; }

			&:first-child { padding-left: 0;
				&:after { display: none; }

			}

		}

	}

	.sublayout-activity-microsite-listing-header { display: table-row;
		.column-1,
		.column-2 { display: table-cell; vertical-align: top; }

		.column-2 { width: 100px; @include clearfix;
			.etteaserimage { float: right; }
		}

		.etpagesubheading { font: bold 14px/16px Arial,Helvetica,sans-serif; padding: 4px 0 8px;

		}

		.etmaincontent { line-height: 18px; padding: 0 20px 18px 0; }

	}

	.activity-microsite-listing-ajax { position: relative; }

	.ajax-loading {
		&:before { content: ""; position: absolute; right: 0; bottom: 0; left: 0; top: 0; z-index: 900; background: #999; @include opacity(.5);	}

		&:after { content: ""; position: absolute; right: 0; left: 0; top: 0; height: 100px; z-index: 901; background: transparent url(/App_Themes/Default/Images/ajax-loader.gif) center bottom scroll no-repeat; }

	}

}

.sublayout-activity-microsite-listing-table { width: 100%; table-layout: fixed; margin-left: -1px; margin-bottom: 10px; empty-cells: show;
	.etquietlink {
		&:link,
		&:visited { text-decoration: inherit; color: inherit; }

	}

	.expanded { 
		.sublayout-activity-microsite-listing-module-row {
			> th { @include gradient-top-bottom( #dc3f3c, #c62825, #dc3f3c ); border-top: solid 1px #eb9e9c; border-bottom: solid 1px #c52723; }

			.etmoduleheading {
				&:before { background-image: url(/App_Themes/Default/Images/TI%20ScienceNspired/expand-list-active-icon.png); }

			}

		}

	}

	.collapsed {
		.sublayout-activity-microsite-listing-module-row {
			> th { @include gradient-top-bottom( #c1c3c4, #9ea1a2, #c1c3c4 ); border-top: solid 1px #e7e7e7; border-bottom: solid 1px #666; }

			.etmoduleheading {
				&:before { background-image: url(/App_Themes/Default/Images/TI%20ScienceNspired/expand-list-inactive-icon.png); }

			}

		}

		.sublayout-activity-microsite-listing-body-row { display: none; }

	}

	&.singlemodule .sublayout-activity-microsite-listing-module-row { cursor: default;
		.etmoduleheading {
			&:before {}

		}

	}

	&.hidemodules {
		@include even( '.sublayout-activity-microsite-listing-body-row', 50 ) { background: #f2f2f2; }

	}

	&.showmodules {
		@include odd( '.sublayout-activity-microsite-listing-body-row', 50 ) { background: #f2f2f2; }

	}

}

.sublayout-activity-microsite-listing-head-row {
	> th { padding: 6px 13px; font: bold 12px/12px Arial,Helvetica,sans-serif; color: #fff; text-align: center; @include gradient-top-bottom( #dd3f3e, #c52623, #dd3f3e ); }

	.column-main { text-align: left; @include clearfix;
		.etcategoryselect { float: right; }

	}

	.column-category { width: 154px; padding: 0 0 0 13px; border-left: 1px solid transparent; vertical-align: middle;
		.etcategoryselect { margin-right: 13px; }

	}

	.column-download { width: 87px; padding: 0; border-left: 1px solid transparent; vertical-align: middle; }
	
	.column-type { width: 60px; padding: 0; border-left: 1px solid transparent; vertical-align: middle; }

	.etcategoryselect { width: 137px; height: 20px; box-sizing: border-box; padding: 1px; border: 1px solid #bfbfbf; }

}

.showmodules .sublayout-activity-microsite-listing-head-row {
	> th { @include gradient-top-middle-bottom( #ececec, #dbdbdb, #e3e3e3, #ececec ); color: #848484; }

}

.sublayout-activity-microsite-listing-module-row { cursor: pointer;
	> th { position: relative; }

	.etmoduleheading { font: bold 13px/27px Arial,Helvetica,sans-serif; color: #fff; padding: 0 8px 0 37px; min-height: 27px;
		&:before { content: ""; display: block; width: 13px; height: 13px; background: transparent none 0 0 scroll no-repeat; border-top: solid 7px #d71500; border-right: solid 8px #d71500; border-bottom: solid 7px #d71500; border-left: solid 8px #d71500; position: absolute; left: 0; top: 0; }

	}

}

.sublayout-activity-microsite-listing-body-row {
	> td { @include clearfix; position: relative; border-left: solid 1px #999; font: 12px/14px Arial,Helvetica,sans-serif; vertical-align: top;
		&:first-child { border-left: 0 none; }

	}

	.column-main { padding: 7px 10px;
		&.with-thumbnail { padding-left: 170px; height: 112px; }

		.etthumbnail { position: absolute; left: 10px; top: 7px;
			img { max-width: 150px; height: auto; }

		}

		.etstandardname { font: bold 12px/14px Arial,Helvetica,sans-serif; }

		.etstandarddescription {}

		.etname { color: #1973b4; font: bold 12px/14px Arial,Helvetica,sans-serif; }

		.etsummary {}

		.etalignment { position: relative; background: transparent url(/App_Themes/Default/Images/Downloads-Activities/icon-check.png) 0 0 scroll no-repeat; min-height: 16px; padding: 3px 0 3px 21px;
			.etstandards {}

			.ettextbook { padding-left: 10px; position: relative; }
			.ettextbook:before{ border-left: 1px solid #525252; content:""; display: inline-block; position: absolute; left: 5px; top: 2px; height: 11px; width: 1px; }
		}

	}

	.column-category { padding: 14px 0 14px 13px;
		.etcategorylist {
			.etcategory { padding: 0 0 2px; position: relative;
				.etcategoryicon{ position: absolute; left: -10px; }
				.etcategoryname{ padding-left: 5px; }
			}

		}

	}

	.column-download { text-align: center; padding: 14px 0;
		.download { display: block; overflow: hidden; width: 18px; height: 18px; text-indent: -9999px; background: transparent url(/App_Themes/Default/Images/activities/icon-download-on.png) 0 0 scroll no-repeat; margin: 0 auto 3px; }

	}
	.column-type  {text-align: center; padding: 10px 0;
		.etcategorylist {
			.etcategory { padding: 4px 0;}
		}
	}	

}