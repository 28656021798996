﻿#divALP_PageHero .image {position: relative;float: left}
  
    /* optional */
  #divALP_PageHero .text {
	position: absolute;
    top: 15px;
    left: 15px;
    width: 280px;
    /* optional, though better have one */
    font-family: Arial,Helvetica,sans-serif;
    font-style: normal;
    font-variant: normal;
    font-weight:normal;
    font-size: 1.20em;
    color: #CB0C0F;	
    word-spacing: normal;
    letter-spacing: normal;
    text-decoration: none;
    text-transform: none;
    text-align: left;
    text-indent: 0ex;
	line-height:1.25em;
	
	p{
		width: 199px;
	}
 }
  
    #divALP_PageHero .heading {color: #CB0C0F; padding-bottom:4px;font-size: 1.75em;font-weight: bold; font-family: Arial,Helvetica,sans-serif; line-height: 1.25em; } 

