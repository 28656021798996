﻿.sublayout-activity-microsite-detail {
    .related-activities {
	    float: left; font-family: Arial,Helvetica,sans-serif; padding: 20px 0; text-align: left; width: 754px;
	    h3 { color: #346FC3; font-size: 13px; font-weight: bold; padding-bottom: 10px; }
	    .jcarousel-skin-tango {
		    .jcarousel-container { background: #e3e3e3 url("/App_Themes/Default/Images/activities/microsites/bg_related_activities.jpg") top left repeat-x;	margin-left: 15px; }
		    .jcarousel-direction-rtl { direction: rtl; 
			    .jcarousel-item-horizontal { margin-left: 10px;	margin-right: 0; }
			    .jcarousel-next-horizontal { left: -15px; right: auto; background-image: url("/App_Themes/Default/Images/activities/microsites/related-activities-prev-inactive.gif"); }
			    .jcarousel-prev-horizontal { left: auto; right: -15px; background-image: url("/App_Themes/Default/Images/activities/microsites/related-activities-right-inactive.gif"); }
		    }
		    .jcarousel-container-horizontal {  width: 452px; padding: 15px 0px 13px 27px; }
		    .jcarousel-clip-horizontal{ width: 100%; }
		    .jcarousel-item { width: 200px; 
			    a { font-size: 11px;	text-decoration: none; 
				    &:hover { text-decoration: underline;}
			    }
		    }
		    .jcarousel-item-horizontal { margin-left: 0; margin-right: 10px; }
		    .jcarousel-item-placeholder { background: #fff; color: #000; }
		    .jcarousel-next-horizontal { position: absolute; top: 55px; right: -15px; width: 11px; height: 14px; cursor: pointer;
			    background: transparent url("/App_Themes/Default/Images/activities/microsites/related-activities-next-active.gif") no-repeat 0 0;
			    &:hover, &:active { background: transparent url("/App_Themes/Default/Images/activities/microsites/related-activities-next-active.gif") no-repeat 0 0; }			
		    }
		    .jcarousel-next-disabled-horizontal { cursor: default; background: transparent url("/App_Themes/Default/Images/activities/microsites/related-activities-next-inactive.gif") no-repeat 0 0;
			    &:hover, &:active { cursor: default; background: transparent url("/App_Themes/Default/Images/activities/microsites/related-activities-next-inactive.gif") no-repeat 0 0; }
		    }
		    .jcarousel-prev-horizontal { position: absolute; top: 55px; left: -15px; width: 11px; height: 14px; cursor: pointer;
			    background: transparent url("/App_Themes/Default/Images/activities/microsites/related-activities-prev-active.gif") no-repeat 0 0;
			    &:hover, &:active { background: transparent url("/App_Themes/Default/Images/activities/microsites/related-activities-prev-active.gif") no-repeat 0 0; }
		    }
		    .jcarousel-prev-disabled-horizontal { cursor: default; background: transparent url("/App_Themes/Default/Images/activities/microsites/related-activities-prev-inactive.gif") no-repeat 0 0;
			    &:hover, &:active { background: transparent url("/App_Themes/Default/Images/activities/microsites/related-activities-prev-inactive.gif") no-repeat 0 0; }
		    }
	    }
    }
}