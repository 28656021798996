﻿
	
	#divHeadingandLink{margin: 0px;padding: 0px 0px 20px;}
    #divHeading{width:auto;height:28px;float:left}
	#divHeading h3{font-family: Arial, Helvetica, sans-serif;margin:0 0 0 20px}
	#divHeading h3 a{color: #CB0C0F;font-size: 14px;font-weight: bold;background-image: none;}
	#divLink{width:auto;height:28px;float:left}
	#divLink h4{font-family: Arial, Helvetica, sans-serif;margin:0 0 0 20px; padding-top: 5px !important}
	#divLink h4 a{padding: 5px 0px 5px 10px;color: #1973B4;font-size: 12px;font-weight: normal;background: url(/App_Themes/Default/Images/activities/tbody-expand.gif) no-repeat 0% 0%;}

	#divSearchNav{background: white url(/App_Themes/Default/Images/activities/search-categories-vertical.gif) no-repeat 100% 100%;float: left;margin: 0px;overflow: hidden;padding-bottom: 29px;width: 177px;margin:0 0 0 10px}
	#activities-find-by ul{list-style-type: none;float:left;font-family: Arial, Helvetica, sans-serif;background-color: #D4E6F4;margin: 0px;overflow: hidden;}
	#activities-find-by li{padding: 7px 0px;background: transparent}
	#activities-find-by li.selected{padding: 7px 0px;background: #D4E6F4 url(/App_Themes/Default/Images/activities/search-categories.gif) repeat-y 0px 0px}
    #activities-find-by li.selected a{color: #CB0C0F;font-weight: bold;}
    #activities-find-by li a{color: #1973B4;cursor: pointer;}

	#divSearchNav #activities-find-by ul{list-style-type: none;float:left;font-family: Arial, Helvetica, sans-serif;background-color: #D4E6F4;margin: 0px;overflow: hidden;}
	#divSearchNav #activities-find-by li{padding: 7px 0px;background: #D4E6F4 url(/App_Themes/Default/Images/activities/search-categories.gif) repeat-y 0px 0px}
	#divSearchNav #activities-find-by li.selected{padding: 7px 0px;background: transparent}
    #divSearchNav #activities-find-by li.selected a{color: #CB0C0F;font-weight: bold;}
    #divSearchNav #activities-find-by li a{color: #1973B4;cursor: pointer;}

	#divSearch{float:left;background: url(/App_Themes/Default/Images/activities/activities-search-hr.gif) no-repeat scroll left top transparent;padding:2px 0 15px 0}

	#divSearchCriteriaHolder{margin:0 0 0 10px;float:left;padding:5px 0 0 50px}
	#divSearchCriteriaHolder .sublayout-etsearchsubject label{font-size: 11px;font-family: Arial, Helvetica, sans-serif}
	#divSearchCriteriaHolder .sublayout-etsearchsubject input { margin: 5px 0 0 75px; font-size: 1em }
	#divSearchCriteriaHolder .etsearchkeyords-lbl{valign: top;font-size: 11px;font-weight: bold;font-family: Arial, Helvetica, sans-serif}
	.etsearchkeywords-space{width: 20px}
	#divSearchCriteriaHolder .etsearchkeywords-txt-btn
	{
		input
		{
			font-family: Verdana, Arial, Helvetica, sans-serif;
			margin: 0 0 0 1px; 
			
		}
	}
	#divSearchCriteriaHolder .etsearchseries-lbl{valign: top;font-size: 11px;font-weight: bold;font-family: Arial, Helvetica, sans-serif}
	.etsearchseries-space{width: 20px}
	#divSearchCriteriaHolder .etsearchseries-ddl-btn
	{
		select 
		{ 
			margin: 0 0 0 1px; 
			
			font-family: Verdana, Arial, Helvetica, sans-serif;
		}
	}
	#divSearchCriteriaHolder .sublayout-etsearchsubject
	 { 
		label 
		{ 
			padding: 4px 0;
			select 
			{ 
				width: 198px; 
				margin: 0 0 0 1px; 
				
				font-family: Verdana, Arial, Helvetica, sans-serif;
			}
		}
	}


	#divSearchCriteriaHolderAdvanced{margin:0 0 0 10px;float:left;padding:10px 0 0 10px}
		
	.SearchCallout .etcallout {clear: left;margin: 0px 0px 12px 0px;}
	.SearchCallout .etcalloutstack-list{clear: left;margin: 0px;padding-bottom: 18px;min-height: 200px;}
	.SearchCallout .etcalloutstack-listitem{float: left;width: 305px;margin-left: 10px;list-style-type: none;}
	.SearchCallout .etcalloutstack-listitem header{padding:0px !important}

	.SearchCallout .etcalloutstack-listitem h1{font-size:11px;display: block;clear: both;font-weight: bold;padding: 5px 10px 0 10px;
		background: #a3a3a3 url(/App_Themes/Default/Images/activities/heading-bg.gif) center bottom scroll repeat-x;
		color: #fff;height: 19px;-moz-border-radius: 5px 5px 0 0;-webkit-border-radius: 5px 5px 0 0;-khtml-border-radius: 5px 5px 0 0;border-radius: 5px 5px 0 0; }
	
	.SearchCallout .etcalloutstack-listitem {background: transparent url(/App_Themes/Default/Images/activities/interior-promo-bg.gif) center bottom scroll no-repeat;background-position-x: 50%;background-position-y: 100%}

	.SearchCallout .etcallout-body div.etcallout-teaserimage{float: left;margin-right: 10px;padding-left:10px}
	.SearchCallout .etcallout-body div.content{font-size:1.0em;line-height: 1.4em;margin: 0px;padding: 0px 0px 0.5em;font-family:Arial, Helvetica, sans-serif}
	.SearchCallout .etcallout-body div.etcallout-teaserimage+div.content{margin:10px 20px 0 20px;}
	.SearchCallout .ethyperlinklist a{font-weight:bold;font-size: 12px;font-family:Arial,Helvetica,sans-serif;color: #CB0C0F;background:url(/App_Themes/Default/Images/activities/chevrons.png) right center scroll no-repeat;text-align:right; padding-left: 0; padding-right: 25px;}
	.SearchCallout .ethyperlinklist li{ padding: 0px 0px 10px 0px; }
	
	