﻿.sublayout-activity-microsite-detail {
	> .column-1 { float: left; width: 185px; }

	> .column-2 { float: left; width: 754px; padding: 19px 0 0 21px; }

		.document-player {padding-left: 18px; background: url("/App_Themes/Default/Images/Downloads-Activities/dp-small.png") left center no-repeat; font-size: 14px; font-weight: bold; text-decoration: none; 
			&:hover{text-decoration: underline;}
		}
		.activity-video-download{ 
		padding: 5px 0 0 3px; 
		background: url("/App_Themes/Default/Images/Downloads-Activities/line-above-download.jpg") no-repeat;
		
		a:link, a:visited, a:focus, a:active, a:link:hover { color: #1973b4; }
		a:focus, a:active, a:link:hover, a:hover { text-decoration: underline; }}

		#video-links li {
				list-style:none !important;
				img { vertical-align:middle; }
				a{
				font-weight:bold;
				color:#CB0C0F;
				}
				}
		

		.subheader{ padding: 0 0 20px 0;
			ul{ margin: 0; list-style: none; clear: left; overflow: hidden;
				li{ float: left; margin-left: 10px}
				li:first-child, li.first-child{ margin-left: 0px; }
			}

			h4, h3{
				clear: none;
				display: inline;
				a{
					clear: none;
				}
			}

			h3,h3 a{
				background-image: none;
				color: $fg-red-header;
				font-size: 14px;
				font-weight: bold;
			}

			h4,h4 a{
				font-weight: normal;
				font-size: 12px;
				color: #606060;
			}

			a{
				color: #1973B4;
				font-size: 12px;
			}

			h4 a, a{
				color: #606060;
				background: url(/App_Themes/Default/Images/Downloads-Activities/tbody-expand.gif) no-repeat top left;
				vertical-align: center;
				padding: 5px 0 5px 10px;
				line-height: 16px;

				&.return{ background: url(/App_Themes/Default/Images/Downloads-Activities/back-red-arrow.png) no-repeat center left; }
			}




		}
		#activity-hero-image-container.fltrt{padding: 0 0 30px 30px; }
		#activity-hero-image-container.fltlft{padding: 0 30px 30px 0; }


		#activity-header + #activity-hero-image-container.fltrt{position: relative; top: -48px; }


		#activity-navigation{
			float: right;
			overflow: hidden;
			ul{ list-style: none; }

			#activity-nav-head{
				width: 214px;
				height: 31px;
				padding-left: 7px;
				margin-top: 57px;
				background: url("/App_Themes/Default/Images/TI Math/sidebar-header-bg.gif") no-repeat;
				li {
					a{
						overflow: hidden;
						text-indent: -9999px;
						display: block;
						width: 19px;
						height: 19px;
					}
					width: 19px;
					height: 19px;
					padding: 6px 7px;
					float: left;
				}
				.likes-icon a{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat 0px 0px; }
				.collection-icon a{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat -19px 0px; }
				.email-icon a{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat -38px 0px; }
				.facebook-icon a{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat -57px 0px; }
				.twitter-icon a{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat -76px 0px; }
				.print-icon a{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat -95px 0px; }

				.likes-icon a:hover{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat 0px -19px; }
				.collection-icon a:hover{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat -19px -19px; }
				.email-icon a:hover{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat -38px -19px; }
				.facebook-icon a:hover{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat -57px -19px; }
				.twitter-icon a:hover{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat -76px -19px; }
				.print-icon a:hover{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat -95px -19px; }

				.collection-icon.saved a{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat -19px -38px; }
				.likes-icon a.recommended{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-ind-act-utility-3row.gif) no-repeat 0px -38px; }
			}
			#activity-nav-actions{
				.icon{float: left; }
				.report-issue{ background: url(/App_Themes/Default/Images/Downloads-Activities/icons-report-issue.gif) no-repeat center left; }
				h5{padding: 8px 0 0 25px; border-bottom: none; margin: 0; }
				background: url("/App_Themes/Default/Images/TI Math/sidebar-content-bg.gif") no-repeat;
				padding: 10px 0 0 10px;
				overflow: visible;
				min-height: 301px;
				width: 214px;
				ul{ padding: 0 30px 0 25px;
					li {
						a { display: block; line-height: 14px; margin-bottom: 4px; }


					}
					h5{padding: 0; }
					li{ padding: 0; }
				}
				p {				
				margin: 0px;
				line-height: 1.4em;
				padding:0 1em 0 25px; }

				.etcategorylist { margin: 0 0 0 -5px; line-height: 14px;
					.etcategory { padding: 0 0 2px; position: relative;
						.etcategoryicon { position: absolute; left: -10px; }

						.etcategoryname { padding-left: 5px; }

					}

				}
			}
		}
		#activity-content{
			width: 508px;
			overflow: hidden;
			#divhero{float:left; width:auto}
			#divflash{float:left; width:auto; padding:0 10px;}
			h3{ color: #CB0C0F; font-size: 14px; font-weight: bold; padding: 0; margin: 0; }
			h4{ color: #606060; font-size: 12px; font-weight: normal; padding: 0; margin: 0; background: #FFF; }
			p{
				font-size: 12px;				
				line-height: 1.4em;
				padding: 0 0 0.5em;
			}

			ul{
				margin: 0 0 15px 0;
				padding-left: 18px;
				li 
				{ 
					list-style-image: url(/App_Themes/Default/Images/Downloads-Activities/bullet-red-5x5.gif);
					font-size: 12px;
					line-height: 1.4em;
					padding: 3px 0px;
				}
			}
			.image-container img, .image-container object, .image-container .image-placeholder {
				padding: 5px;
				
				@include border-radius( 5px 5px 5px 5px );
			}

			.image-placeholder { overflow: hidden; width: 240px; height: 240px;
				span { display: block; width: 240px; height: 240px; background: #fff; text-align: center; }
			}

			.underline{
				padding-bottom: 5px;
				margin-bottom: 5px;
				border-bottom: #737373 1px solid;
				display:block;
			}

			.ad-overline{
				padding: 10px 0 0 0;
				margin: 12px 0 10px 0;
				border-top: #737373 1px solid;
			}

			.fltrt img{ margin-left: 15px; }
			.fltlft img{ margin-right: 15px; }

			&.OVA, &.BDA {
				#divhero{float:right; width:auto; text-align:right;}
				#divflash{float:right; width:auto; text-align:right; padding-left: 12px}
				#activity-header{ float: left; }
				#activity-info-container{clear: left; }
				.image-container{
					float: right;
					&.fltlft img{ margin: 0 0 0 15px }

				}
				h4 {
					color: #346FC3;
					font-size: 13px;
					font-weight: bold;
					padding-bottom: 5px;
				}

				#divflash{padding: 0px 0px 0px 12px;}
			}

			&.BDA {
				.activity-info-container { padding-bottom: 10px; }
				li { padding-bottom: 5px; }
			}

					.activity-overview-container{ 
		h4{
		    color: #5F5F5F;
			font-size: 14px;
			font-weight: bold;}}

		}


		#activity-header{
			
			overflow: hidden;
			span.page_title_red, .page_title {
				color: #CB0C0F;
				font-size: 20px;
				font-family: Helvetica;
				font-weight: bold;
				padding-bottom: 15px;
			}

		}

		.activity-overview-container,
		#activity-info-before,
		#activity-info-during,
		#activity-info-after{margin-bottom: 15px; }



		h3{ font-size: 18px; color: #606060; display: block; }
		h5, h4{ clear: none; font-size: 14px; font-weight: bold; color: #5f5f5f; padding-top: 20px; }
		h5{ font-size: 12px; padding: 10px 0 0 0;  a{ font-weight: bold; } }
		#activity-key-steps-container{
			clear: both;
			padding-top: 10px;

			h4{
		    color: #5F5F5F;
			font-size: 14px;
			font-weight: bold;}

			#slideshow{
				position: relative;
				* html & { height: 205px; }

				ul{ min-height: 205px;
					overflow: visible;
					padding: 0;
					margin: 0;
				}
				li.slide {
					width: 508px;
					position: absolute;
					top: 10px;
					list-style-type: none;
					list-style-image: none;

					img{
						float: right;
						margin: 0 0 0 20px;
					}

					p{
						margin: 0px;
						line-height: 1.4em;
						padding: 0 0 0.5em;
					}

				}
			}
			#pagination-list{
				float: right;
				li{
					float: left;
					display: block;
					text-align: center;
					line-height: 16px;
					font-size: 11px;
					width: 16px;
					height: 16px;
					margin: 2px;
					background: #b3b3b3;
					color: #fff;
					font-weight: bold;
				}
				li.selected{
					background: #c21600;
				}
			}
		}

	}