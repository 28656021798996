﻿#divALP_CalloutModule1 div.etcallout {height:190px;width:680px;background:url(/App_Themes/Default/Images/activities/ax-landing-promo-bg-3col.gif) no-repeat;overflow:hidden}
#divALP_CalloutModule1 .etcalloutstack-list{padding:10px 0 0 10px}
#divALP_CalloutModule1 .etcalloutstack-listitem{float:left;width:192px;margin-right:11px;height:170px;padding:0px 10px 0px 10px;position:relative;left:-10px}

#divALP_CalloutModule1 .etcalloutstack-listitem h1{padding:10px 0 0 15px; font-weight:bold; font-size:1.3em; clear:both; color:#cb0c0f}
#divALP_CalloutModule1 .etcallout-body div.etcallout-teaserimage
{
	float:left;
	width:80px;
	padding:10px 0 0 15px;
}
#divALP_CalloutModule1 .etcallout-body div.content{margin:5px 10px 0 0;font-size:11px;font-family:Arial, Helvetica, sans-serif;padding:0 10px 0 0;width:160px}
#divALP_CalloutModule1 .etcallout-body div.etcallout-teaserimage+div.content{margin:0 0 0 50px; padding-top: 10px;}

//clear:left;position:absolute;bottom:20px;right:10px;clear:both;bottom:15px;
#divALP_CalloutModule1 .ethyperlinklist { width:160px;font-weight:bold;font-size:1.0em;font-family:Arial,Helvetica,sans-serif;text-align:right;padding:0px 0px 0px 45px;position: absolute;top: 130px; }
#divALP_CalloutModule1 .ethyperlinklist a{color:#cb0c0f;background:url(/App_Themes/Default/Images/activities/chevrons.png) right center scroll no-repeat;padding: 0px 25px 0px 0px;float: right;}

#divCalloutModule2 .etcallout {height:85px;width:280px;background:url(/App_Themes/Default/Images/activities/ax-landing-right-promo-bg.gif) no-repeat;overflow:hidden}
#divCalloutModule2 .etcalloutstack-list{padding:0px 0 0 0px}
#divCalloutModule2 .etcalloutstack-listitem{float:left;width:280px;margin-right:11px;height:85px;padding:0px 10px 0 0px;position:relative}
#divCalloutModule2 .etcalloutstack-listitem header{width: 190px; padding: 10px 0; float: right;}

#divCalloutModule2 .etcalloutstack-listitem h1{padding:0px 0px 0px 0px; margin:0px 0px 0px 0px; font-weight:bold; font-size:1.3em; clear:both; color:#cb0c0f;text-align:center}
#divCalloutModule2 .etcallout-body div.etcallout-teaserimage{float:left;width:80px;padding:0;}
#divCalloutModule2 .etcallout-body div.content{margin:0 10px 0 0;font-size:11px;font-family:Arial, Helvetica, sans-serif;padding:0 10px 0 0;width:200px}
#divCalloutModule2 .etcallout-body div.etcallout-teaserimage+div.content{margin:0 0 0 80px}
#divCalloutModule2 .ethyperlinklist a{clear:left;bottom:20px;right:10px;width:180px;font-weight:bold; font-size:1.0em; clear:both; color:#cb0c0f;background:url(/App_Themes/Default/Images/activities/chevrons.png) right center scroll no-repeat;text-align:left;padding:0 25px 0 10px}
 
#divALP_SearchFields .sublayout-etsearchsubject input { float: left; margin: 5px 0 0 110px; }
  
label.searchlbl{ margin-left: 10px; overflow: hidden; }
    
    .activities-search-advanced table tbody tr td h4 {border-bottom: 1px solid; display: block; font-weight: bold; font-size: 12px; color: #606060; padding: 0px !important; }
    .activities-search-advanced table tbody tr td  {background: none repeat scroll 0 0 #F1F1F1;text-align: left !important;width: auto !important; padding: 6px 10px 4px; vertical-align:top;  }
    .activities-search-advanced table tbody tr th {background: #D6D6D6 none !important; width: auto; text-align:left; padding: 0 10px;}
    .activities-search-advanced table tbody tr  {color: #525252;  font-family: Arial,Helvetica,sans-serif;}
    .activities-search-advanced table tbody tr.first-child th h3 {color: #6E6E6E;font-size: 11px;line-height:20px;font-weight:bold;font-family:Arial,Helvetica,sans-serif;}
    .activities-search-advanced table tbody tr td ul  {clear:both; overflow: hidden;margin-bottom: 15px;font-size: 1em;font-family:Arial,Helvetica,sans-serif; }
    .activities-search-advanced table tbody tr td ul li{ float: left; list-style: none outside none; width: 350px; }
	.activities-search-advanced table tbody tr td ul.cblLevel li{ float: left; list-style: none outside none; width: 175px; }
	.activities-search-advanced table tbody tr td ul li.odd{ float: left; list-style: none outside none; width: 350px; }
    .activities-search-advanced table tbody tr td ul li.even {list-style: none outside none;}
    .activities-search-advanced table { width:740px; font-size: 11px; }
	.activities-search-advanced { margin: 10px 0 0 0; }
	 input.checkbox { background: none repeat scroll 0 0 transparent;vertical-align: middle;}

	.activities-search-advanced{
	  table {
		  tbody {
			  tr th h3 { display: inline !important; padding: 5px 15px; font-weight: bold;}

			  tr th h3:hover {text-decoration: underline}

				 tr th h3 {
					background: url("/App_Themes/Default/Images/Downloads-Activities/tbody-expand.gif") no-repeat scroll 0 -2px transparent;
					color: #6E6E6E;
					display: inline-block;
					font-size: 11px;
					line-height: 20px;
				}
			}
			tbody.expanded tr th h3 {  background-position: 0 -26px;}
		}
	}

	#carousel #week-pager{width:280px;margin:36px auto}
    #carousel #week-pager a{text-align:center}
    #carousel #week-pager a h3{display:inline}
    #carousel{height:250px;width:280px; list-style: none outside none}
    #carousel li{height:250px; list-style: none outside none}
    
    div.jcarousel-prev{position:absolute;top:217px;left:5px;height:28px;width:20px;background:url(/App_Themes/Default/Images/activities/chevrons-white-backward.png) right center scroll no-repeat;cursor:pointer}
    div.jcarousel-next{position:absolute;top:217px;right:5px;height:28px;width:20px;background:url(/App_Themes/Default/Images/activities/chevrons-white-forward.png) right center scroll no-repeat;cursor:pointer}
    #alpfeaturedactivity_0_pnlWeeklyActivity {height:250px;width:280px}

	#carousel .calc-1000{background:url(/App_Themes/Default/Images/activities/bgs/tinspirecxcas.jpg) no-repeat;overflow:hidden}
    #carousel .calc-1000 img{ width: 153px; height: 114px; margin: 74px 0 0 66px; opacity: .8; filter: alpha(opacity=80); -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=80)"}

	#carousel .calc-1001{background:url(/App_Themes/Default/Images/activities/bgs/tinspirecx.jpg) no-repeat}
    #carousel .calc-1001 img{ width: 153px; height: 114px; margin: 74px 0 0 66px; opacity: .8; filter: alpha(opacity=80); -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=80)"}

	#carousel .calc-1002{background:url(/App_Themes/Default/Images/activities/bgs/tinspirecas.jpg) no-repeat}
    #carousel .calc-1002 img{ width:146px; height: 114px ;margin:75px 0 0 69px; opacity:.5; filter: alpha(opacity=50);-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=50)"}
    #carousel .calc-1002 img.overlay{width:146px;height:114px;position:absolute}
    #carousel .calc-1002 a h3{margin:36px 0 0;padding:0}

	#carousel .calc-1003{background:url(/App_Themes/Default/Images/activities/bgs/tinspire.jpg) no-repeat}
    #carousel .calc-1003 img{ width:146px; height: 114px ;margin:75px 0 0 69px; opacity:.5;filter: alpha(opacity=50);-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=50)"}
    #carousel .calc-1003 img.overlay{width:146px;height:114px;position:absolute}
    #carousel .calc-1003 a h3{margin:36px 0 0 0;padding:0}

	#carousel .calc-1004{background: url(/App_Themes/Default/Images/activities/bgs/ti84plus_c_silver_edition.jpg) no-repeat;}
    #carousel .calc-1004 img{width: 134px;height: 86px;margin: 66px 0 0 76px;opacity: .5;filter: alpha(opacity=50);-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";}
    #carousel .calc-1004 img.overlay{width: 134px;height: 86px;position: absolute;}
    #carousel .calc-1004 a h3{margin: 74px 0 0 0;padding: 0;}

	#carousel .calc-1005{background:url(/App_Themes/Default/Images/activities/bgs/ti84plus_silver_edition.jpg) no-repeat}
    #carousel .calc-1005 img{ width:135px; height: 90px ;margin:67px 0 0 74px; opacity:.5;filter: alpha(opacity=50);-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=50)"}
    #carousel .calc-1005 img.overlay{width:135px;height:90px;position:absolute}
    #carousel .calc-1005 a h3{margin:56px 0 0 0;padding:0}

	#carousel .calc-1006{background:url(/App_Themes/Default/Images/activities/bgs/ti84plus_silver_edition.jpg) no-repeat}
    #carousel .calc-1006 img{ width:135px; height: 90px ;margin:67px 0 0 74px; opacity:.5;filter: alpha(opacity=50);-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=50)"}
    #carousel .calc-1006 img.overlay{width:135px;height:90px;position:absolute}
    #carousel .calc-1006 a h3{margin:56px 0 0 0;padding:0}

	#carousel .calc-1007{background:url(/App_Themes/Default/Images/activities/bgs/ti73explorer.jpg) no-repeat}
    #carousel .calc-1007 img{width:154px;height:96px;margin:73px 0 0 66px;opacity:.5;filter:alpha(opacity=50);-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=50)"}
    #carousel .calc-1007 img.overlay{width:154px;height:96px;position:absolute}
    #carousel .calc-1007 a h3{margin:56px 0 0 0;padding:0}

	#carousel .calc-1008{background:url(/App_Themes/Default/Images/activities/bgs/ti89titanium.jpg) no-repeat}
    #carousel .calc-1008 img{ width:134px; height: 86px ;margin:66px 0 0 76px; opacity:.5;filter: alpha(opacity=50); -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=50)"}
    #carousel .calc-1008 img.overlay{width:134px;height:86px;position:absolute}
    #carousel .calc-1008 a h3{margin:74px 0 0 0;padding:0}

	

    #carousel .calculator{margin:0;width:280px;height:250px;position:relative}
    #carousel .calculator div.activity-info{width:280px;height:37px}
    #carousel .calculator div.activity-info .activity-area,#carousel .calculator div.activity-info .activity-name{position:absolute;bottom:7px;font-size:11px;text-align:center;color:#fff;display:block;width:216px;margin:0 32px;-moz-text-overflow:ellipsis;-o-text-overflow:ellipsis;-webkit-text-overflow:ellipsis;text-overflow:ellipsis;height:13px}

    #divFeaturedActivity .calculator div.activity-info .activity-area:hover,#carousel .calculator div.activity-info .activity-name:hover{text-decoration:none}
    #divFeaturedActivity .calculator div.activity-info .activity-area{bottom:23px;font-weight:bold}
    #carousel .calculator a,#carousel .calculator a:hover{text-decoration:none}
    #divFeaturedActivity h2.first-child{position:absolute;top:10px;padding:0 0 0 20px}
    #carousel h3,#carousel h4{padding:0;font-size:11px;font-weight:bold;text-align:center;color:#fff}
    
   

   h2.weekly-header{position:absolute;top:10px;margin:100px 0 0 16px;clear: both;color: #CB0C0F;font-size: 1.3em;font-weight: bold;}

	#divMPA{padding:10px 0 0 0}    
    .MPA_Title{padding:0px 0px 0px 20px;clear: both;color: #CB0C0F;font-size: 1.3em;font-weight: bold;font-family: Arial, Helvetica, sans-serif}
    .MPA_View, .MPA_Search{padding: 0px 10px 0px 0px;text-align:right;clear: none;color: #525252;float: none;font-size: 14px;font-weight: bold;cursor: default}
	.MPA_View_LAR {padding: 0px 10px 0px 0px;text-align:right;clear: none;color: #525252;float: none;font-size: 14px;font-weight: bold;cursor: default}
    .MPA_View select, .MPA_Search select{width:190px;margin:5px 0px;background: white;color: #525252;font-family: Verdana, Arial, Helvetica, sans-serif;font-size: xx-small;display: inline-block;letter-spacing: normal;line-height: normal;}
	.MPA_View_LAR select {width:210px;margin:5px 0px;background: white;color: #525252;font-family: Verdana, Arial, Helvetica, sans-serif;font-size: xx-small;display: inline-block;letter-spacing: normal;line-height: normal;}
    .MPA_SearchResults {width: 250px; height:212px; padding: 5px 10px 0 20px}
    .MPA_More a{color: #CB0C0F;float: right;padding: 0 0 0 0;font-weight: bold;font-size: 1em;font-style: normal;font-family: Arial, Helvetica, sans-serif; padding: 10px 0}
    .MPA_More a:link:hover {text-decoration: underline}
	#popular-activities li{border-bottom: 1px solid #CBCBCB;height: 85px;padding: 10px 0px}
	#popular-activities li .img-container {background: #D8D8D8;float: left;padding: 5px}
	#popular-activities li .activity-stats {margin: 0px 0px 0px 110px}
	#popular-activities h3 {font-size: 12px;font-weight: bold;text-align: left; padding-bottom: 10px;}
	#popular-activities-lar li{border-bottom: 1px solid #CBCBCB;height: 50px;padding: 10px 0px}
	#popular-activities-lar li .activity-stats {margin: 0px}
	#popular-activities-lar li .activity-stats-counts {margin-left: 15px;}
	#popular-activities-lar li .activity-stats-counts span {margin: 0px 8px;}
	#popular-activities-lar h3 {font-size: 12px;font-weight: bold;text-align: left}
	