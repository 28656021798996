﻿.sublayout-activity-microsite-navigation { position: relative; font: 13px/18px Arial,Helvetica,sans-serif; background: #fff; padding: 15px 0 100px;
	&:before { content: ""; display: block; position: absolute; right: 0; top: 0; bottom: 0; width: 14px; @include gradient-left-right( rgba(212, 212, 212, 0), rgba(212, 212, 212, 1), #fff ); z-index: 4; }

	&:after { content: ""; display: block; position: absolute; right: 0; bottom: 0; width: 14px; height: 32px; @include gradient-top-bottom( rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), #fff ); z-index: 5; }

	.nav-1 { position: relative; z-index: 8; padding: 0 21px 3px 0;
		&:before { content: ""; display: block; position: absolute; left: 2px; right: 0; bottom: 0; height: 1px; background: #d7d7d7; z-index: 6; }

		&:after { content: ""; display: block; position: absolute; left: 2px; bottom: 0; width: 30px; height: 1px; @include gradient-left-right( rgba(255, 255, 255, 1), rgba(255, 255, 255, 0), #fff ); z-index: 7; }

		.nav-1a { display: block; padding: 4px 0 4px; margin: 5px 0 0 15px; line-height: 14px; text-align: right;
			&:link,
			&:visited { color: #1973b4; }

		}

		&.selected .nav-1a:link,
		&.selected .nav-1a:visited,
		.nav-1a:link:hover,
		.nav-1a:visited:hover { color: #cb0c0f; }

		.nav-2 { font-size: 11.4px; margin: -2px 0 0;
			.nav-2a { display: block; padding: 3px 0 1px; margin: 0 0 5px 15px; line-height: 14px; text-align: right; position: relative;
				&:link,
				&:visited { color: #525252;
					&:hover,
					&.selected { color: #cb0c0f; }

					&.selected {
						&:before { content: ""; display: block; position: absolute; border-left: 6px solid #c00; border-top: 4px solid transparent; border-bottom: 4px solid transparent; width: 0; height: 0; right: -12px; top: 7px; }

					}

				}

			}

		}

		&.collapsed {
			.nav-2 { display: none; }

		}

	}

}