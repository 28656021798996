﻿.sublayout-etsearchsubject { width: auto; margin: -5px 10 0 0px;
				label { display: block; font-weight: bold; text-align: right; font-size: 14px; padding: 7px 0; color:#525252;
					select { width: 198px; padding: 1px 0; margin: 0 0 0 1px; }
				}
				
				a.submit { margin: 5px 0 0 110px; font-size: 1em }

}




