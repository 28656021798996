﻿	/****************** Styling for subheader class ***********************/
	.subheader
    { padding: 0 0 20px 15px;                
	    ul 
	    {
	    margin: 0; list-style: none; clear: left; overflow: hidden;
		    li{ float: left; margin-left: 10px}
		    li:first-child, li.first-child{ margin-left: 0px; }
	    }
	    h4, h3{
		    clear: none;
		    display: inline;
		    a{
			    clear: none;
		    }
	    }
	    h3,h3 a{
		    background-image: none;
		    color: $fg-red-header;
		    font-size: 14px;
		    font-weight: bold;
	    }
	    h4,h4 a{
		    font-weight: normal;
		    font-size: 12px;
		    color: #1973B4;
	    }
	    h4 a, a{
		    color: #606060;
		    background: url(/App_Themes/Default/Images/Downloads-Activities/tbody-expand.gif) no-repeat top left;
		    vertical-align: center;
		    padding: 5px 0 5px 10px;
		    line-height: 16px;

		    &.return{ background: url(/App_Themes/Default/Images/Downloads-Activities/back-red-arrow.png) no-repeat center left; }
	    }
	    a{
		    color: #1973B4;
		    font-size: 12px;
	    }
	}
	/**************************************************************************************************************/
	#submit-steps
	{
		padding: 0 0 0 15px;
	}
	
	#submit-steps li.expanded {
		h4{ background-position: 10px -24px; }
	}

	#submit-steps li.collapsed {
		h4{ background-position: 10px 0px; }
	}
	/********************************************************COLLAPSE/EXPAND*****************************************************************/

	
	.expand-list
	{
        li
        {
            list-style: none;
            clear: left;
            
            li
            { 
                clear: none; 
            }

            h4
            { 
                font-weight: bold; 
                padding: 0 0 0 25px; 
                margin-bottom: 0px;
                background: #D6D6D6 url(/App_Themes/Default/Images/Downloads-Activities/tbody-expand.gif) 10px 0 no-repeat;
                display: block; 
                line-height: 24px; 
                height: 24px;
                a
                {
                    color: #606060; 
                    cursor: pointer; 
                    line-height: 24px; 
					font-size: 12px;
					font-weight: normal;
                }
				span
				{
					color: #606060; 
                    cursor: pointer; 
                    line-height: 24px; 
					font-size: 12px;
					font-weight: bold;
				}
            }
            
            h5
            { 
                font-size: 12px; 
                font-weight: bold; 
                margin-bottom: 5px; 
                border-bottom: #525252 1px solid; 
                display: block; 
            }
            
            input[type="submit"], input.submit
            {
                margin: 10px 0 0 10px;
                &.center
                {
                    margin: 10px auto 0 auto!important; 
                    display: block; 
                }
                &.left
                {
                    margin: 10px 0 !important
                }
            }
            
            .submit-line .submit
            {
                &:first-child, &.first-child 
                { 
                    margin-left: 0px; 
                }
            }
            
            .expand-content
            { 
                padding: 0 10px 10px 25px; 
            }
            
            .inline-fields
            { 
                overflow: hidden; 
            }
            
            .inline-field, .inline-fields li
            {
                width: 300px;
                float: left;
                padding: 10px 0 0 0;
                overflow: hidden;
                clear: none;
                label, span
                {
                    display: inline-block; 
                    width: 80px; 
                    text-align: right; 
                    vertical-align: top; 
                }
            
                input[type="text"], input.text
                {
                    width: 175px; 
                }
            }
        
            .inline-field
            {
                width: auto;
                clear: both;
                float: none;
				line-height: 16px;
            
                input[type="text"], input.text
                {
                    width: 400px;
                    &.time-field
                    {
                        width: 100px; 
                    }
                }
    
                textarea
                {
                    width: 550px;
                    height: 75px; 
                }
            
                div
                {
                    display: inline;
                    div
                    {
                    	display: block; 
                    }
                }

				.persisted-file
				{
					display: block; 
					padding-left: 83px;
					input.submit,input[type="submit"]
					{ 
						margin: 10px 0 0 11px; 
					}
				}
            }        
            
			.inline-checkbox-field
			{
				padding: 10px 0 0 80px;
				label, span
				{ 
					padding: 0 0 0 5px; 
				}
			}
            
            .description
            { 
                display: block; 
                float: right; 
                width: 400px; 
                vertical-align: top; 
            }

			.three-col
			{
				overflow: hidden;
				li
				{ 
					width: 288px; 
					padding-right: 12px; 
					float: left; 
				}
				h5
				{ 
					border: none; 
					margin: 10px 0 0 14px; 
				}
			}
        }

        .inline-radio-field
        {
            padding: 10px 0 0 0;
            label, span
            { 
                float: left; 
                display: inline-block; 
                width: 80px; 
                text-align: right; 
            }
            
            ul
            {
                overflow: hidden;
                label
                { 
                    float: none; 
                    margin: 0 0 0 5px; 
                    width: auto; 
                    text-align: left; 
                }
            
            li
            { 
                float: left; 
                width: 125px; 
                clear: none; 
            }
        }        
        
        .two-col
        {
            overflow: hidden;
            li
            { 
                width: 430px; 
                padding-right: 20px; 
                float: left; 
            }
            h5
            { 
                border: none; 
                margin: 10px 0 0 14px; 
            }
        }    
        
    
        .file-upload 
        {
            height: 24px; 
            width: 456px; 
            display: inline-block; 
            position: relative;
            .ie7 &, .ie6 &
            { 
                zoom: 1; 
                display: inline; 
            }
            .upload-button 
            { 
                display: inline-block; 
            }
            .jcUpload-filename 
            {
                 width: 118px; 
            }
            *:first-child+html & 
            {
                width: 405px; 
                height: auto;
                
                .persisted-file 
                { 
                    padding-left: 0px; 
                    width: 800px;
                    input.submit, input[type="submit"] 
                    {
                        margin-left: 0px; 
                    }
                }
            }
        }
	}	
	}

	#layout-default
	{
		#submit-review
		{ 			
			.expand-content 
			{
				padding: 0px 0px 10px 0px;
				p
				{
					strong
					{
						font-weight: bolder;
					}
				}
				.activity-detail
				{
					float: left;
				}
				input
				{
					bottom: 10px;
					position: relative;
				}
			} 
		}
		#submit-terms
		{
			#submission-agreement
			{
				margin: 10px 25px;
			}
			.submit-line
			{
				text-align: center;
			}
		}
	}
	
	#submit-personal-information 
	{ .inline-checkbox-field { padding: 10px 0px 0px 80px; }}
	
	.submit-details-image-preview-panel .file-upload 
	{ 
		width: 360px;
		.jcUpload-filename 
		{ 
			width: 113px; 
		}
	}
	
	.submit-details-file-upload-panel .file-upload 
	{ 
		display: inline-block;
		width: 250px;
		.ie7 &, .ie6 &
		{ 
			zoom: 1; 
			display: inline; 
			width: 240px; 
		}
		.jcUpload-filename 
		{ 
			width: 118px; 
		}
	}
	
	#submit-details .two-col li.selected img, #submit-details .three-col li.selected img, #submit-details .two-col li.selected img, #submit-details .three-col li.selected img 
	{
		border: #cb0c0f 1px solid; 
	}

	#submit-details .expand-content #style .submit-pane ul.three-col li.selected img
	{
		border: #cb0c0f 1px solid; 
	}

	/*********************************************************ERROR*************************************************************************/
	.error
	{
		background: $bg-error;
		color: $fg-error;
		overflow: hidden;
		border: solid 1px $fg-error; padding: 10px;
		display: block;
		ul{
			padding-left: 20px;
			li { list-style-type: disc!important; }
		}
	}
	/***************************************************radius********************************************************/
	@mixin border-radius( $radius ) 
	{ 
		-moz-border-radius: $radius; 
		-webkit-border-radius: $radius; 
		-khtml-border-radius: $radius; 
		border-radius: $radius; 
	}
	/***************************************************content_button************************************************/
	@mixin content_button 
	{ 
		height: 22px;
		text-align: center; 
		background: transparent url(/App_Themes/Default/Images/Downloads-Activities/button-bg.gif) 0 0 scroll repeat-x; 
		border: solid 1px #828488;
		padding: 0 18px; margin: 9px 5px 0 0; 
		color: #6e6e6e; 
		cursor: pointer; 
		clear: none;
		@include border-radius( 4px ); font-weight: bold; 
	}
	/***************************************************Styling for Upload********************************************/
	#section-body .jcUpload 
	{ 
		@include content_button; 
		display: inline-block; 
		margin: 0; 
		line-height: 22px; 
		min-height: 22px; 
		height: 22px; 
		background-position: left center; 
		position: relative; 
		width: auto; 
		cursor: pointer; 
		overflow: visible;
		span 
		{ 
			line-height: 22px; 
			cursor: pointer; 
            display: inline-block; 
            text-align: center; 
            vertical-align: top; 
		}
		iframe 
		{ 
			position: absolute; 
			width: 100%; right: 0; left: 0; top: 0; 
			height: 22px; 
			cursor: pointer;
			body
			{ 
				margin: 0px; 
			}
		}
		.jcUpload-filename 
		{ 
			position: absolute; 
			right: -133px; 
			top: 0; 
			white-space: nowrap; 
			overflow: hidden; 
			font-weight: bold; 
			color: #fff; 
			line-height: 21px; 
			padding: 2px 5px; 
			display: inline-block; 
			height: 21px; 
			background: #c1272d url(/App_Themes/Default/Images/Downloads-Activities/progress.gif) 0 0 repeat-x; @include border-radius( 4px ); cursor: default; 
		}
	
		.ie7 &, .ie6 &
		{ 
			zoom: 1; 
			display: inline; 
		}	
	}
	/********************************************************SUBMIT DETAILS*****************************************************************/
	#submit-pane-activity-style
	{ 
		padding: 10px 0; 
		display: inline-block; 
	}
	.submit-details-block, .submit-details-button-block 
	{
		padding-bottom: 10px;
		input[type="button"],
		input[type="submit"],
		input.button,
		input.submit 
		{ 
			@include content_button;  
			float: none; 
			margin: 0; 
		}	
	}
	.submit-details-button-block 
	{
		input[type="button"],
		input[type="submit"],
		input.button,
		input.submit 
		{ 
			margin-right: 20px!important; 
		}
	}
	.submit-details-description-panel 
	{
		padding: 2px 0 3px 0;
		padding-left: 140px;
		textarea 
		{ 
			width: 610px; height: 90px; 
		}
	}
	.submit-details-file-upload-panel 
	{
		padding: 2px 0 3px 0;
		clear: both;
		input[type="text"],
		input.text{width: 240px; }
		label
		{
			width: 137px; 
			display:inline-block; 
			text-align: right; 
		}
		div
		{
			overflow: hidden; 
			padding: 3px 0 2px 0; 
			div
			{
				padding: 0; 
			}
		}
		.submit-details-file-rename-button,
		.submit-details-file-remove-button
		{
			float:right!important; 
			margin-right:10px!important; 
			display: inline-block;
			.ie7 &, .ie6 &
			{ 
				position: relative; bottom: 20px; 
			}
		}	
		.ie7 & 
		{
			file-upload{width: 225px; }
		}
	}
	
	.submit-details-image-preview-uploader
	{
		display:block; 
		float: right; 
		padding-right: 14px; 
	}
	
	.submit-details-file-upload-list-panel
	{
		width: 540px;
		float: right;
		.submit-details-file-upload-list
		{
			width: 350px; 
			height: 90px; 
			float: left; 
		}
		p
		{ 
			width: 180px; 
			float: right; 
		}	
	}
	.submit-details-image-preview-panel
	{
		padding: 2px 0 3px 0;
		clear: both;
		overflow: hidden;
		label
		{
			width: 137px; 
			display:inline-block; 
			text-align: right; 
		}
		p
		{
			float: right; 
			width: 390px; 
		}
	}
	.char-limit
	{ 
		margin-left: 15px; 
		vertical-align: top;
		&.error
		{ 
			padding: 2px; 
		}
	}
	/********************************************************SUBJECTS AND PRODUCTS*****************************************************************/
	#subjects
	{
		overflow: hidden;
		clear: both;
		.subject
		{
			float: left;
			width: 280px;
			padding-right: 20px;
			clear: none;
	
			h6
			{
				cursor: pointer;
				&:hover,
				&.hover
				{
					text-decoration: underline; 
				}
			}
	
			.subject-area
			{
				h6
				{
					padding: 0 0 3px 10px;
					display: block;
					background: transparent url(/App_Themes/Default/Images/Downloads-Activities/tbody-expand.gif) 0px -30px no-repeat;
				}
				&.collapsed h6
				{
					background-position: 0px -5px; 
				};
				ul
				{ 
					overflow: hidden; 
					padding:0 0 0 10px; 
				}
			}
		}
	}
	/****************************************************************************************************************************************/
	#products
	{
		overflow: hidden;
		clear: both;
		li
		{
			ul
			{
				overflow: hidden;
				clear: both;
				padding-bottom: 20px;
				li
				{ 
					float: left; 
					width: 300px; 
					clear: none; 
				}
			}
		}
	
		#products-handheld,
		#products-software
		{
			height: 260px;
			width: 430px;
			float: left;
	
			ul
			{
				clear: none;
				li
				{ 
					width: 210px;
				}
			}
		}
		#products-handheld
		{ 
			padding-right: 15px; 
		}
		#products-applications
		{ 
			padding-right: 30px;
			ul
			{
				overflow: hidden;
				clear: both;
				padding-bottom: 20px;
				li
				{ 
					float: left;
					width: 440px;
					clear: none;
				}
			}
		}
	}
	/*********************************************************************************************************************************/
	#nspire-version
	{
		background: #deecfe;
		border: #d1def0 1px solid;
		margin-top: 10px;
		padding: 10px;
		label
		{ 
			padding-left: 15px; 
		}
		@include border-radius( 5px );
	}
	/*********************************************************Activity Preview********************************************************/
	#submit-review .activity-detail
	{
		#activity-navigation
		{
			float: left;
			overflow: hidden;
			ul
			{ 
				list-style: none; 
			}
		
			#activity-nav-head
			{
				width: 245px;
				height: 31px;
				background: url(/App_Themes/Default/Images/Downloads-Activities/sidebar-header-bg.gif) no-repeat;
				li 
				{
					a
					{
						overflow: hidden;
						text-indent: -9999px;
						display: block;
						width: 19px;
						height: 19px;
					}
					width: 19px;
					height: 19px;
					padding: 6px 0 0 18px;
					float: left;
				}				
			}
			#activity-nav-actions
			{
				.icon{float: left; }
				.report-issue{ background: url(/images/icons/icons-report-issue.gif) no-repeat center left; }
				h5
				{
					padding: 8px 0 0 25px; 
					border-bottom: none; 
					margin: 0; 
				}
				background: url(/App_Themes/Default/Images/Downloads-Activities/sidebar-content-bg.gif) no-repeat;
				padding: 10px 0 0 10px;
				overflow: visible;
				min-height: 301px;
				width: 245px;
				ul
				{ 
					padding: 0 0 0 25px;
					li 
					{
						a
						{
							line-height: 18px; 
						}
					}
					h5
					{
						padding: 0; 
					}
					li
					{ 
						padding: 0; 
					}
				}
				p 
				{
					padding:0 1em 0 25px; 
				}
			}
		}
	
		#activity-content
		{
			width: 645px;
			overflow: hidden;
			float: left;

			h3{ color: #CB0C0F; font-size: 14px; font-weight: bold; padding: 0; margin: 0; }
			h4{ color: #606060; font-size: 12px; font-weight: normal; padding: 0; margin: 0; background: #FFF; }
			p{
				margin: 5px 0 15px 0;
			}

			ul{
				margin: 0 0 15px 0;
				padding-left: 18px;
				li { list-style-image: url(/App_Themes/Default/Images/Downloads-Activities/bullet-red-5x5.gif);
				}
			}
			.image-container img, .image-container object, .image-container .image-placeholder {
				padding: 5px;
				background: $border;
				@include border-radius( 5px 5px 5px 5px );
			}
			#divhero
			{
				float: left;
				.image-placeholder 
				{ 
					overflow: hidden; 
					width: 240px; 
					height: 240px;
					background: #CACACA;
					border-bottom-left-radius: 5px 5px;
					border-bottom-right-radius: 5px 5px;
					border-top-left-radius: 5px 5px;
					border-top-right-radius: 5px 5px;
					padding: 5px;

					img 
					{ 
						display: block; 
						width: 240px; 
						height: 240px; 
						background: #fff; 
						text-align: center; 
					}
				}
			}

			.underline{
				padding-bottom: 5px;
				margin-bottom: 5px;
				border-bottom: #737373 1px solid;
				display:block;
			}

			.overline{
				padding: 10px 0 0 0;
				margin: 5px 0 10px 0;
				border-top: #737373 1px solid;
			}

			.fltrt img{ margin-left: 15px; }
			.fltlft img{ margin-right: 15px; }

			&.OVA{
				#activity-header{ float: left; }
				#activity-overview-container{clear: left; }
				.image-container{
					float: right;
					&.fltlft img{ margin: 0 0 0 15px }

				}
			}
	
		}
	}


	#activity-overview-container,
	#activity-info-before,
	#activity-info-during,
	#activity-info-after{margin-bottom: 15px; }
	h3{ font-size: 18px; color: #606060; display: inline-block; }
	h5, h4{ clear: none; font-size: 14px; font-weight: bold; color: #5f5f5f; padding-top: 20px; }
	h5{ font-size: 12px; padding: 10px 0 0 0;  a{ font-weight: bold; } }
	#activity-key-steps-container{
		clear: both;
		padding-top: 10px;
		#slideshow{
			position: relative;
			.ie6 & { height: 205px; }

			ul{ min-height: 205px;
				overflow: visible;
				padding: 0;
				margin: 0;
			}
			li.slide {
				width: 645px;
				position: absolute;
				top: 10px;
				list-style-type: none;
				list-style-image: none;

				img{
					float: right;
					margin: 0 0 0 20px;
				}

				p{
					margin-top: 0px;
				}

			}
		}
		#pagination-list{
			float: right;
			li{
				float: left;
				display: block;
				text-align: center;
				line-height: 16px;
				font-size: 11px;
				width: 16px;
				height: 16px;
				margin: 2px;
				background: #b3b3b3;
				color: #fff;
				font-weight: bold;
			}
			li.selected{
				background: #c21600;
			}
		}
	}
    
	input, textarea, select, button 
	{
		
		color: #525252;    
		font-size: x-small;
	}