﻿
#thanks{ 
	padding: 4px 7px 10px 17px;
	min-height: 460px;
	p {
		line-height: 1.4em;
		margin: 0px;
		padding: 0px 0px 0.5em;
		font-family: Arial, Helvetica, sans-serif;
		color: #525252;

		a {
			border: 0px none;
			font-size: 1em;
			font-style: normal;
			font-weight: normal;
			margin: 0px;
			padding: 0px;
			text-decoration: none;
			font-family: Arial, Helvetica, sans-serif;
		}
		a:link, a:visited, a:focus, a:active, a:link:hover
		{
			color: #1973B4;
			cursor: pointer;
			font-family: Arial, Helvetica, sans-serif;
		}
	}
}