﻿.sublayout-activity-microsite-landing { position: relative; width: 960px; overflow: hidden;
	&:before { content: ""; display: block; position: absolute; left: 0; right: 0; top: 0; height: 7px; @include gradient-top-bottom( rgba(172, 172, 172, 1), rgba(172, 172, 172, 0), #fff ); z-index: 10; }

	> .column-1 { float: left; width: 185px; }

	> .column-2 { float: left; width: 775px;  
        .sublayout-activity-microsite-landing-header { padding: 19px 0 0 22px; z-index:11; position: relative;
	        .right-column { margin:0px; padding: 30px 30px 0 20px; display:table-cell;}
	        .left-column { margin:0px; padding: 0; display:table-cell; vertical-align: top;
		        
                .etpageheading { font: bold 20px/20px Arial,Helvetica,sans-serif; color: #cb0c0f;}
                .etpagesubheading { font: bold 14px/22px Arial,Helvetica,sans-serif, verdana; padding-top: 10px;}
                .content{ font: normal 12px/20px Arial,Helvetica,sans-serif, verdana; padding: 10px 0 20px 0} 
                .sublayout-activity-microsite-mega-dropdown { min-height:50px; position:relative;
                    .mega-dropdown-button { color: #525252; float: left; font-size: 15px; font-weight: bold; height: 30px; overflow: hidden; padding-left: 10px; cursor:pointer; border: 1px solid #525252;
                        background:url("/App_Themes/Default/Images/activities/microsites/ui-selector-bg-global.png") repeat scroll 0 0; //background-color: #e4e4e4;
                        &:after { content: "";  display: inline-block; background: url("/App_Themes/Default/Images/activities/microsites/ui-dropdown-global.png") no-repeat 10px 0; width: 40px; height: 30px; vertical-align: middle;}
                    }
                    .mega-dropdown.active { visibility: visible; }

                    .mega-dropdown {
                        visibility: hidden;     
                        overflow: hidden;
                        -moz-border-bottom-colors: none;
                        -moz-border-left-colors: none;
                        -moz-border-right-colors: none;
                        -moz-border-top-colors: none;
                        background: none repeat scroll 0 0 #ffffff;
                        border-color: -moz-use-text-color #bababa #bababa;
                        border-image: none;
                        box-shadow: 0px 0px 20px rgba(50, 50, 50, 0.75);
                        left: 0;
                        position: absolute;
                        top: 30px;
                        z-index: 900;
                        ul { position: relative;
	                        .mega-dropdown-column {
								position: relative;
	                            width: 228px;
	                            font: bold 14px/22px Arial,Helvetica,sans-serif;
	                            height:auto;
	                            float:left;
	                            color: #cb0c0f;
	                            padding: 0 0 5px 5px;	                            
	                            ul{
	                                padding-left:10px;
	                                li {
	                                    border:0px;
	                                    padding: 0;	                                    
	                                    a { font: normal 12px/22px Arial,Helvetica,sans-serif; }
	                                }
	                            }
	                        }							
	                        li.mega-dropdown-column + li.mega-dropdown-column:before {
	                        	content:"";
	                        	display: block;
	                        	background: url(/App_Themes/Default/Images/product-detail/vertical-hr.png) no-repeat scroll 0 -15px;
	                        	width: 2px;
	          					position: absolute;
	          					height: 401px;
	          					left: -15px;
	                        }
                        }
                    }
                }           
	        }
        }

        .sublayout-activity-microsite-landing-content-module { clear:both; padding: 0 22px 0; min-height: 150px;
            
            .etproductcontentmodule {
                background: none repeat scroll 0 0;
				.etproductcontentmodule-inner { padding: 0px;
					.etmoduleheading { color: #cb0c0f; font: 20px/27px Arial,Helvetica,sans-serif; margin: 0 8px 10px 5px; }
					.etproductcolumns {
						.etcolumnimage { float: initial; margin: 0;}
						.etproductcontentcolumn-maincontent {                        
							.etcolumnheading { color: #cb0c0f; font: bold 15px/20px Arial,Helvetica,sans-serif; margin: 0 auto 10px; }
							.etmaincontent {font: normal 12px/18px Arial,Helvetica,sans-serif; padding: 0 10px;
							   a.learnmore{
									display: inline;
									width: 185px;
									color: #c1272d;
									font-size: 1.1em;
									line-height: 1.1em;
									font-weight: bold;
									text-align: right;
									padding-right: 25px;
									background: url(/App_Themes/Default/Images/General/chevrons.png) scroll right center no-repeat transparent;        					
								}
							}
						}
					}
				}				
            }
            .sublayout-etproductcontentmodule-variablecolumn.columns-3 {                
                .sublayout-etproductcontentcolumn {
                    background: none repeat scroll 0 0; 
                    padding: 0 10px; 
                    width:223px;
                    .etcolumnimage {
                        img {width:220px;}
                    }
                    text-align: center;
                    
                }
                
            }

            .sublayout-etproductcontentmodule-variablecolumn.columns-2 {                
                .sublayout-etproductcontentcolumn {
                    background: none repeat scroll 0 0; 
                    padding: 0 10px; 
                    width:335px;
                    text-align: center;
					.etmaincontent{ font: normal 12px/18px Arial,Helvetica,sans-serif; padding: 0 20px;}
                }
                
            }

        }

    }

}
