﻿

		.activity-search-results
        {
            border-collapse: collapse;
            clear: both;
            margin: 5px 0 0 10px;
            width: 950px;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 1em;
            font-style: normal;
            font-weight: normal;
            border-spacing: 2px 2px;
            border-color: gray;
            display: table;
        }
        .activity-search-results thead
        {
            border-color: inherit;
            display: table-header-group;
            vertical-align: middle;
            border-collapse: collapse;
        }
        .activity-search-results tr
        {
            border-color: inherit;
            display: table-row;
            vertical-align: inherit;
        }
        .activity-search-results thead tr th
        {
            background: #A3A3A3 url(/App_Themes/Default/Images/activities/heading-bg.gif) repeat-x 50% 100%;
            font-weight: bold;
			color: white;
            vertical-align: middle;
            padding: 0px 10px 4px 10px;
            width: 108px;
        }
		.activity-search-results thead tr
		{
			th.name
			{
				text-align: left;
				border-bottom-left-radius: 0px 0px;
				border-bottom-right-radius: 0px 0px;
				border-top-left-radius: 5px 5px;
				border-top-right-radius: 0px 0px;
			}
			th.author, th.grade-level, th.product
			{
				text-align: center;
			}
			th.save
			{
				border-bottom-left-radius: 0px 0px;
				border-bottom-right-radius: 0px 0px;
				border-top-left-radius: 0px 0px;
				border-top-right-radius: 5px 5px;
			}
		}
        .activity-search-results thead th.download span, .activity-search-results tbody a.download
        {
            background: transparent url(/App_Themes/Default/Images/activities/icon-download-on.png) no-repeat 50% 0px;
            background-position: 50% 50%;
            display: block;
            height: 25px;
            overflow: hidden;
        }
        .activity-search-results tbody a.download
        {
			cursor:pointer;
        }

        .activity-search-results thead th.recommend span, .activity-search-results tbody li.recommend_on
        {
            background: transparent url(/App_Themes/Default/Images/activities/icon-like-on.png) no-repeat 50% 0px;
            background-position: 50% 50%;
            display: block;
            height: 25px;
            overflow: hidden;
        }
		.activity-search-results tbody li.recommend_off
		{
			background: transparent url(/App_Themes/Default/Images/activities/icon-like-off.png) no-repeat 50% 0px;
            background-position: 50% 50%;
            display: block;
            height: 25px;
            overflow: hidden;
			cursor:pointer;
		}
        .activity-search-results thead th.save span
        {
            background: transparent url(/App_Themes/Default/Images/activities/icon-collection-header.png) no-repeat 50% 0px;
            background-position: 50% 50%;
            display: block;
            height: 25px;
            overflow: hidden;
        }
		.activity-search-results tbody li.save_on
        {
            background: transparent url(/App_Themes/Default/Images/activities/icon-collection-on.png) no-repeat 50% 0px;
            background-position: 50% 50%;
            display: block;
            height: 25px;
            overflow: hidden;
			cursor:pointer;
        }
		.activity-search-results tbody li.save_off
		{
			background: transparent url(/App_Themes/Default/Images/activities/icon-collection-off.png) no-repeat 50% 0px;
            background-position: 50% 50%;
            display: block;
            height: 25px;
            overflow: hidden;
			cursor:pointer;
		}
        
        .activity-search-results tbody
        {
            border-top: 1px solid white;
            border-color: inherit;
            display: table-row-group;
            vertical-align: middle;
            border-collapse: collapse;
        }
        .activity-search-results tbody tr
        {
			td.name
			{
				padding: 10px 10px 10px 10px;
				text-align: center;
				vertical-align: top;
				line-height: 14px;
			}
			td.author, td.grade-level, td.product, td.downloads, td.likes-icon, td.collection-icon
			{
				padding: 10px 10px 10px 10px;
				border-left: 1px solid #999;
				text-align: center;
				vertical-align: top;
				line-height: 14px;
			}
        }
        .activity-search-results tbody tr th.name, .activity-search-results tbody tr td.name
        {
            text-align: left;
            width: 560px;
			padding: 10px 10px 10px 10px;
        }
		.activity-search-results tbody tr td.name h4, .activity-search-results-grp tbody tr td.name h4
        {
            padding: 0px !important;
        }
        .activity-search-results tbody tr th.author, .activity-search-results tbody tr td.author
        {
            background: transparent none no-repeat scroll 9px 7px;
            padding: 11px 0px 0px 8px;
            text-align: left;
			width: 109px;
        }
        .activity-search-results tbody tr td.download, .activity-search-results tbody tr td.recommend, .activity-search-results tbody tr td.save
        {
            text-align: center;
            width: 80px !important;
        }
        .activity-search-results tbody tr td.download a
        {
            background: transparent url(/App_Themes/Default/Images/activities/icon-download-on.png) no-repeat 50% 0px;
        }
        .activity-search-results tbody tr td.recommend a
        {
            background: transparent url(/App_Themes/Default/Images/activities/icon-like-on.png) no-repeat 50% 0px;
        }
        .activity-search-results tbody tr td.save a
        {
            background: transparent url(/App_Themes/Default/Images/activities/icon-collection-on.png) no-repeat 50% 0px;
        }
		.activity-search-results tbody tr td.grade-level li
		{
			padding: 0px 0px 3px 0px;
		}
        .activity-search-results tbody tr td h4 a
        {
            color: #CB0C0F;
            display: block;
            font-weight: bold;
			font-size: 12px;
            margin-bottom: 5px;
        }
		.activity-search-results tbody tr{
			&.even, &.even-row
		    {
			    background-color: #F2F2F2;
			}
		}
		.activity-search-results tbody tr td.name div.alignment, .activity-search-results-grp tbody tr td.name div.alignment
        {
            margin-top: 5px;

			strong
			{
				font-weight: bold;
			}
        }
		.silverShade 
		{
			background-color: #D6D6D6;
		}


		.activity-search-results-grp
        {
            border-collapse: collapse;
            clear: both;
            margin-top: 5px;
            width: 960px;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 1em;
            font-style: normal;
            font-weight: normal;
            border-spacing: 2px 2px;
            border-color: gray;
            display: table;
        }
        .activity-search-results-grp thead
        {
            border-color: inherit;
            display: table-header-group;
            vertical-align: middle;
            border-collapse: collapse;
        }
		.activity-search-results-grp thead tr
		{
			th.name
			{
				text-align: left;
				border-bottom-left-radius: 0px 0px;
				border-bottom-right-radius: 0px 0px;
				border-top-left-radius: 5px 5px;
				border-top-right-radius: 0px 0px;
			}
			th.author, th.grade-level, th.product
			{
				text-align: center;
			}
			th.save
			{
				border-bottom-left-radius: 0px 0px;
				border-bottom-right-radius: 0px 0px;
				border-top-left-radius: 0px 0px;
				border-top-right-radius: 5px 5px;
			}
		}
        .activity-search-results-grp tr
        {
            border-color: inherit;
            display: table-row;
            vertical-align: inherit;
        }
        .activity-search-results-grp thead tr th
        {
            background: #A3A3A3 url(/App_Themes/Default/Images/activities/heading-bg.gif) repeat-x 50% 100%;
            font-weight: bold;
			color: white;
            vertical-align: middle;
            padding: 0px 10px 4px 10px;
            width: 108px;
        }
        .activity-search-results-grp thead th.download span, .activity-search-results-grp tbody a.download
        {
            background: transparent url(/App_Themes/Default/Images/activities/icon-download-on.png) no-repeat 50% 0px;
            background-position: 50% 50%;
            display: block;
            height: 25px;
            overflow: hidden;
        }

        .activity-search-results-grp thead th.recommend span, .activity-search-results-grp tbody li.recommend_on
        {
            background: transparent url(/App_Themes/Default/Images/activities/icon-like-on.png) no-repeat 50% 0px;
            background-position: 50% 50%;
            display: block;
            height: 25px;
            overflow: hidden;
        }
		.activity-search-results-grp tbody li.recommend_off
		{
			background: transparent url(/App_Themes/Default/Images/activities/icon-like-off.png) no-repeat 50% 0px;
            background-position: 50% 50%;
            display: block;
            height: 25px;
            overflow: hidden;
		}
        .activity-search-results-grp thead th.save span
        {
            background: transparent url(/App_Themes/Default/Images/activities/icon-collection-header.png) no-repeat 50% 0px;
            background-position: 50% 50%;
            display: block;
            height: 25px;
            overflow: hidden;
        }
		.activity-search-results-grp tbody li.save_on
        {
            background: transparent url(/App_Themes/Default/Images/activities/icon-collection-on.png) no-repeat 50% 0px;
            background-position: 50% 50%;
            display: block;
            height: 25px;
            overflow: hidden;
        }
		.activity-search-results-grp tbody li.save_off
		{
			background: transparent url(/App_Themes/Default/Images/activities/icon-collection-off.png) no-repeat 50% 0px;
            background-position: 50% 50%;
            display: block;
            height: 25px;
            overflow: hidden;
		}
        
        .activity-search-results-grp tbody
        {
            border-top: 1px solid white;
            border-color: inherit;
            display: table-row-group;
            vertical-align: middle;
            border-collapse: collapse;
        }
        .activity-search-results-grp tbody tr
        {
			td.name
			{
				padding: 10px 10px 10px 10px;
				text-align: center;
				vertical-align: top;
				line-height: 14px;
			}
			td.author, td.grade-level, td.product, td.downloads, td.likes-icon, td.collection-icon
			{
				padding: 10px 10px 10px 10px;
				border-left: 1px solid #999;
				text-align: center;
				vertical-align: top;
				line-height: 14px;
			}
        }
        .activity-search-results-grp tbody tr td.name
        {
            text-align: left;
            width: 560px;
			padding: 10px 10px 10px 10px;
        }
        .activity-search-results-grp tbody tr td.author
        {
            background: transparent none no-repeat scroll 9px 7px;
            padding: 11px 0px 0px 8px;
            text-align: left;
			width: 109px;
        }
        .activity-search-results-grp tbody tr td.download, .activity-search-results-grp tbody tr td.recommend, .activity-search-results-grp tbody tr td.save
        {
            text-align: center;
            width: 80px !important;
        }
        .activity-search-results-grp tbody tr td.download a
        {
            background: transparent url(/App_Themes/Default/Images/activities/icon-download-on.png) no-repeat 50% 0px;
        }
        .activity-search-results-grp tbody tr td.recommend a
        {
            background: transparent url(/App_Themes/Default/Images/activities/icon-like-on.png) no-repeat 50% 0px;
        }
        .activity-search-results-grp tbody tr td.save a
        {
            background: transparent url(/App_Themes/Default/Images/activities/icon-collection-on.png) no-repeat 50% 0px;
        }
		.activity-search-results-grp tbody tr td.grade-level li
		{
			padding: 0px 0px 3px 0px;
		}
        .activity-search-results-grp tbody tr td h4 a
        {
            color: #CB0C0F;
            display: block;
            font-weight: bold;
			font-size: 12px;
            margin-bottom: 5px;
        }
		.search-options select.groupSelect {float:right}
		.search-options select.pageSizeSelect {float:right}
		.search-options span { font-weight: bold; }
		.search-options { padding: 15px 0px; }
		.search-options
		{
			margin-left: 10px;
			select
			{
				background: white;
				color: #525252;
				font-family: Verdana, Arial, Helvetica, sans-serif;
			}
		}

		.activity-search-results-grp tbody tr.groupByHeader th.silverShade
		{
			border-top: 1px solid white;

			h3.collapsed
			{
				clear: left;				
				overflow: hidden;
				padding-left: 12px;
				margin-left: 10px;
				background: transparent url("/App_Themes/Default/Images/activities/tbody-expand.gif") no-repeat scroll 0px -2px;

				a
				{
					font-weight: bold;
					color: #6E6E6E;
					display: inline-block;
					font-size: 11px;
					line-height: 15px;	
					font-family: Arial, Helvetica, sans-serif;			
				}
			}

			h3.expanded
			{
				clear: left;				
				overflow: hidden;
				padding-left: 12px;
				margin-left: 10px;
				background: transparent url("/App_Themes/Default/Images/activities/tbody-expand.gif") no-repeat scroll 0px -26px;

				a
				{
					font-weight: bold;
					color: #6E6E6E;
					display: inline-block;
					font-size: 11px;
					line-height: 15px;	
					font-family: Arial, Helvetica, sans-serif;			
				}
			}
		}
		
		.search-options .paggingStyle
		{
			padding: 0 3px;
            border-left: 1px solid #CACACA;
		}

		b, strong { font-weight: bold; }
		i, em { font-style: italic; }
		sup 
		{
			font-size: smaller;
			vertical-align: super;
		}
		sub 
		{
			font-size: smaller;
			vertical-align: sub;
		}