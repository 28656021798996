﻿.etactivities-subject-navigation { padding-top: 18px;
	h1 { font-size: 1.2em; font-weight: bold; }

	nav {
		ul {
			li { width: 190px; position: relative;
				nav { display: none; }

				&.subjects-all:hover { background: none; }

				&.selected {
					& > a { font-weight: bold; color: #cb0c0f; }

					nav { display: block; padding-left: 18px; }

				}

			}

		}

	}

}

.sublayout-etactivities-search-controls { font-size: 1.112em; float: left; width: 190px; padding: 0 18px 18px; line-height: 1.4em; position: relative; @include box-shadow( 7px 0 7px 0 #eaeaea );
	.collapse,
	.expand { position: absolute; display: block; overflow: hidden; text-indent: -9999px; }

	.collapse { width: 17px; height: 17px; background: url(/App_Themes/Default/Images/activities-search/icon-hide.png) scroll center center no-repeat; left: 202px; top: 5px; }

	.expand { width: 34px; height: 29px; background: url(/App_Themes/Default/Images/activities-search/icon-show.png) scroll center center no-repeat; left: 225px; top: 0; }

	h1 { font: 1.25em Arial,Helvetica,sans-serif; color: #878787; padding: 0 0 0 20px; @include gradient-top-bottom( #fff, #e7e7e7, #fff ); margin: 0 -18px; border-top: solid 2px #e7e7e7; border-bottom: solid 2px #e7e7e7; line-height: 26px; }
	h2 { font-size: 1.2em; font-weight: bold; }

	.etactivities-search-criteria {

		.etactivities-search-criterion { padding-top: 18px;
			ul { overflow: hidden;
				li { overflow: hidden; }

			}

			&.fewer ul {
				li { display: none;
					&.checked,
					&.featured { display: block; }

				}

			}

			li.featured { display: block; }

			li.checked { display: block;
				label { background-image: url(/App_Themes/Default/Images/activities-search/checkbox-checked.png); font-weight: bold; }

			}

			label { display: block; padding-left: 18px; margin-top: 2px; vertical-align: top; background: url(/App_Themes/Default/Images/activities-search/checkbox-unchecked.png) left 1px no-repeat; cursor: pointer; overflow: hidden; }

			a.criteria-toggle { color: #1973b4; font-weight: bold; cursor: pointer; padding-left: 18px; }

			input[type="checkbox"] { position: absolute; margin-left: -9999px; }
			
			a.etactivities-search-hyperlink { display: block; color: #1973b4; font-weight: bold; }

		}

		.etactivities-search-criteria-topics { display: none; }

	}
}

.sublayout-etactivities-search-header { overflow: hidden; padding: 21px 0; margin: 0 0 12px;
	.keyword-search { width: 200px; padding: 0 28px 0 19px; float: left; overflow: hidden;
		.input-keywords { font-size: 1.25em; width: 160px; float: left; margin: 1px 0 0; }
		input[type="button"],
		input[type="submit"] { float: left; margin: 6px 6px 0; }

	}

	input[type="button"],
	input[type="submit"],
	input.submit { border: none; width: 20px; height: 14px; padding: 0; font-size: 0em; text-indent: -9999px; text-transform: capitalize; text-align: left; background: url(/App_Themes/Default/Images/thin-red-line/search-button.png) right center no-repeat; }

	.etsearchheading { color: #cb0c0f; font-size: 1.6em; width: 535px; float: left;
		a { color: #cb0c0f; }

	}

	.etactivities-search-navigation { overflow: hidden; float: left; width: 160px;
		ul { float: right; overflow: hidden;
			li { line-height: 1.8em;
				a { padding-right: 20px; font-weight: bold; background: url(/App_Themes/Default/Images/thin-red-line/search-button.png) right center no-repeat; }

			}

		}

	}

}

.sublayout-etactivities-search-results { float: right; padding: 0 18px; font-size: 1.112em; width: 697px; margin: -3px 0 0;
	.criteria-bucket { clear: both; overflow: hidden; padding-top: 9px; padding-bottom: 2px;
		.bucket-title { font-weight: bold; font-size: 1.25em; padding-right: 9px;
			&:last-child { display: none; }

		}

		.criteria-item { display: inline-block; padding-left: 18px; vertical-align: top; background: url(/App_Themes/Default/Images/activities-search/checkbox-x.png) left 1px no-repeat; font-size: 1.25em; cursor: pointer; line-height: 18px; white-space: nowrap; margin-right: 9px; }

	}

	.search-options { margin-left: 0;
		.groupSelect { margin-left: 9px; }

	}

	.etactivities-search-results-content-wrapper { position: relative; min-height: 300px; }

	.activity-search-results { width: auto; margin-left: 0;
		tbody tr td.author { padding: 10px; }

	}

}

html.submitting {
	.etactivities-search-results-content-wrapper { background: url(/App_Themes/Default/Images/activities-search/loading.gif) no-repeat center 115px; }

	.etactivities-search-results-content { visibility: hidden; }

}

.sublayout-etactivity-asynchronous-search-options {
	.etactivity-asynchronous-search-options { display: table-row;
		h1 { font: bold 12px Arial,Helvetica,sans-serif; color: #525252; display: table-cell; padding-right: 9px; }

		.etpaging { display: table-cell; padding-right: 9px;
			h2 { display: table-cell; padding-right: 9px; font: bold 12px Arial,Helvetica,sans-serif; color: #525252; }

			nav { display: table-cell;
				a { display: inline-block; font: bold 12px Arial,Helvetica,sans-serif; color: #525252; margin: 0 2px;
					&:link,
					&:visited { color: #1973b4; }
				}
			}
		}

		.etsortgroup { display: table-cell; margin-right: 9px; }

		.etpagesize { display: table-cell; }
	}
}